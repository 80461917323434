<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Données de restauration</h4>
          <p class="card-title-desc">
            Utilisez cette section pour avoir une vision sur les données des
            badgeuses.
          </p>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Base</label>
                <multiselect
                 @input="getSingleCampServicesData"
                  :searchable="true"
                  v-model="filters.camp"
                  track-by="name"
                  label="name"
                  :options="campsList"
                  placeholder=""
                  :select-label="''"
                  :allow-empty="false"
                >
                  <template slot="singleCamp" slot-scope="{ camp }">{{
                    camp.name
                  }}</template>
                </multiselect>
              </div>
            </div>
            <div class="col-md-2" v-for="(meal, index) in mealsData" v-bind:key="index">
              <h5>{{ meal.serviceName }}</h5> <br>
              <h2>{{ meal.serviceTotal }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  mounted() {
    this.fetchCampsListFromReader();
  },
  data() {
    return {
      campsList: [],
      mealsData: [],
      sallesList: [],
      filters: {
        camp: "",
        salle: "",
      },
    };
  },

  methods: {
      getSingleCampServicesData(){
          var campName = this.filters.camp['name'];
          var loader = this.$loading.show();
      this.$http
        .post("/system/access/syncServicesByCamp", {
            campName: campName
        })
        .then((res) => {
          loader.hide();
          this.mealsData = res.data.data;
        })
        .catch((error) => {
          loader.hide();
        })
        .finally(() => {});
      },
    fetchCampsListFromReader() {
      var loader = this.$loading.show();
      this.$http
        .post("/system/access/syncCamps")
        .then((res) => {
          loader.hide();
          this.campsList = res.data.original.campsList;
        })
        .catch((error) => {
          loader.hide();
        })
        .finally(() => {});
    },
  },
};
</script>
