<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import repas from "./repas";
import {getDropDownElementsByTypeApi} from "@/api/common";
// import SideButtons from '../../../../../../components/side-buttons.vue';

export default {
    page: {
        title: "Contrôle & Validation ODS",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/*SideButtons*/repas},
    props:{
      ODS:{
        type:Array,
        default:undefined
      },
      leftAmountContract:{}
    },
    data() {
      return {
      tableData: [],
      title: "Contrôle & Validation",
      selectedBdv:0,
      restaurants:[],
      ODS_info: {
        Bdv:'',
        ID_CTT:'',
        prestataire:'',
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      },
      Repas:{},
      send:false,
      blocked:true,
      RepasService:[],
      typeRepas:[],
      have:false,
      keys:'',
      bases:[],
      prests:[],
      contracts:[],
      periods:[],
      filtredRepas:[]

    };
  },
  watch:{
    ODS:{
      handler(val){
        this.Repas={};
        if(val.length > 0){
          // console.log(val);
          this.ODS_info.id                  =  val[0].id;
          this.ODS_info.ref                 =  val[0].ref;
          this.ODS_info.type_ODS            =  val[0].type_ODS;
          this.ODS_info.Bdv                 =  val[0].Bdv;
          this.ODS_info.ID_CTT              =  val[0].contract;
          this.ODS_info.prestataire         =  val[0].service_provider;
          this.ODS_info.date_ODS            =  val[0].date_ODS;
          this.ODS_info.date_exec_ODS       =  val[0].date_exec_ODS;
          this.ODS_info.period              =  val[0].period;
          this.ODS_info.lifebase            =  val[0].lifebase;
          this.ODS_info.done                =  val[0].done;
          this.ODS_info.verified            =  val[0].verified;
          this.ODS_info.comments            =  val[0].comment;
          this.ODS_info.is_extra            =  val[0].is_extra==1 ? true : false;
          this.ODS_info.total_amount        =  val[0].totalAmnt.plain;
          this.ODS_info.items               =  val[0].items;
          this.RepasService                 =  Object.keys(val[0].items);
          this.filtredRepas                 =  val[0].items;
          this.RepasService.forEach(element => (this.Repas[element] = undefined))
          this.selectedContract             =  val[0].contract;
          this.selectedBdv                  =  val[0].lifebase;
          this.bases.push(this.selectedBdv);
          this.selectedPrest                =  val[0].service_provider;
          this.have                         =  true;

          this.$emit('getContractID',val[0].contract);
        }
        else this.resetData()
      }
    }
  },
  mounted(){
    this.getLifeBases();
    // this.getServiceRepas();
    this.getlistPeriod();
  },

  methods:{
    getLifeBases(){
      this.$http.post('/base/lifebases/list')
      .then(response => {
          this.bases = response.data.original.list;
      })
      .catch(error => console.log(error))
    },
    onComplete(){
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text: "de Valider le Contrôle de cet ODS ? ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2b8a3e',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Oui',
      }).then((result) => {
        if (result.isConfirmed) {
          // this.getTotalAmountODS();
          this.$http.post('/ods/OdsRestauration/validation',{
              ods:this.ODS_info, items:this.Repas
          })
          .then(response => {
              this.send = true;
              this.$emit('odsvalues',response.data);
              Swal.fire("Félicitations!", "Commande Vérifier avec succées", "success");
              this.resetData();
              this.send=false;
          })
          .catch(error => console.log(error))
        }
      })
    },
    onFenced(){
      
      // console.log(this.Repas);
      this.$http.post('/ods/OdsRestauration/validation',{
          ods:this.ODS_info, items:this.Repas,fenced:true
      })
      .then(response => {
          this.send = true;
          this.$emit('odsvalues',response.data);
           Swal.fire("Félicitations!", "Commande Vérifier avec succées", "success");
          this.resetData();
          this.send=false;
      
      })
      .catch(error => console.log(error))
    },
    getServiceRepas(){
      this.$http.post('/ods/OdsRestauration/listRepas')
      .then(response => {
        let List = response.data;
        
        List.forEach(element => {
            element.type_period = element.type_period.split("");
            element.value = false;
          });
          // console.log(List);
        this.RepasService = List;
        
      })
      .catch(error => console.log(error))
    },
    getTypeRepas(){
      this.$http.post('base/operations3D/restauration/typeRepas/list')
      .then(response => {
        let List = response.data;
        
        List.forEach(element => {
            element.value = false;
          });
        this.typeRepas = List;
      })
      .catch(error => console.log(error))
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    getlistPeriod(){
      getDropDownElementsByTypeApi("meal_period")
      .then(res => {
        // console.log(res);
        this.periods = res.data.original.list
      })
      .catch(error => (console.log(error)))
    },
    resetData(){
      this.ODS_info = {
        Bdv:'',
        ID_CTT:'',
        prestataire:'',
        contract:'',
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      }
      this.Repas = [];
      this.RepasService = [],
        this.filtredRepas = [],
        this.have=false
      this.send = true;
    },
    getTotalAmountODS(){
        var total=0;
        // console.log(this.Repas);
        let keys = Object.keys(this.Repas);
        var Omeals;
        keys.forEach(element => {
            if(this.Repas[element]){
              let list = Object.keys(this.Repas[element])
              list.forEach(x => {
                
                if(this.ODS_info.is_extra !=1) Omeals = (parseInt(this.Repas[element][x].meal_verified_amount) < parseInt(this.Repas[element][x].prestation.default_qty)) ? parseInt(this.Repas[element][x].prestation.default_qty ): parseInt(this.Repas[element][x].meal_verified_amount);
                else Omeals = parseInt(this.Repas[element][x].meal_verified_amount);
                total += Omeals * (this.Repas[element][x].unit_price ? parseInt(this.Repas[element][x].unit_price) : parseInt(this.Repas[element][x].prestation.unit_price));
              })
            } 
        })
        // console.log(total);
        if(total){
          if(total > this.leftAmountContract){
              Swal.fire(
                'Dépassement',
                `Vous avez dépassé le montant restant du contrat de: ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(Math.abs(this.leftAmountContract - total))}`,
                'error'
              )
              // this.blocked = true;
          }  
          else{
            Swal.fire(
                'Correct',
                `Montant Restant du contract après validation: ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(this.leftAmountContract - total)}`,
                'success'
              )
              this.blocked=false
          }
          // console.log('total verif= '+total+' leftAmount= '+this.leftAmountContract);
          this.ODS_info.total_amount_verified = total;
          this.$emit('totalCurrentOds',total);
          return total;
        }
        else {
          total=0
          this.$toast.error("Veuillez Vérifier vos informations");
          this.ODS_info.total_amount_verified = 0;
          this.$emit('totalCurrentOds',total);
          // console.log('total verif= '+total);
          return total;
        }
        
    },
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <!-- <SideButtons :save="send" :contract="ODS_info.contract" @add="add = $event" @save="save = $event" @edit=" edit = $event" @confirm=" confirm = $event" @validate=" validate = $event" @delete=" destroy = $event" /> -->
        
              <div class="p-3">
                <div class="">
                  <!-- <h4 class="card-title">New </h4> -->
                      <div class="row">

                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-password-input">N° Contrat</label>
                                <input class="form-control" readonly v-model="ODS_info.ID_CTT.ref" />
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-inputCity"
                                  >Nom de la Base *</label
                                >
                                <input v-if="ODS_info.lifebase" class="form-control" readonly v-model="ODS_info.lifebase.name"/>
                                <input v-else class="form-control" readonly v-model="ODS_info.Bdv"/>
                              </div>
                            </div>

                            <div class="col-lg-4">
                                <label for="">Prestataire *</label>
                                <input v-if="ODS_info.prestataire" class="form-control" readonly v-model="ODS_info.prestataire.prestname"/>
                                <input v-else class="form-control" readonly v-model="ODS_info.prestataire"/>
                                <!-- <select class="form-control" disabled v-model="ODS_info.prestataire">
                                    <option value="0">Selectionner le Prestataire</option>
                                    <option v-for="prest in prests" :key="prest.value" :value="prest.value">{{prest.text}}</option>
                                </select> -->
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-4">
                                <label for="">Date ODS</label>
                                <input type="date" readonly class="form-control" v-model="ODS_info.date_ODS">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Date execution</label>
                                <input type="date" readonly class="form-control" v-model="ODS_info.date_exec_ODS">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Type de Periode *</label>
                                <select class="form-control" disabled v-model="ODS_info.period">
                                    <option value="0">Selectionner la periode</option>
                                    <option v-for="period in periods" :key="period.id" :value="period.id">{{period.designation}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Commentaires</label>
                                <textarea
                                  v-model="ODS_info.comments"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  readonly
                                ></textarea>
                              </div>
                            </div>
                        </div>

                      <!-- end row -->
                </div>
                <!-- end card-body -->
          </div>
          <div class="p-3">
            <div class="row d-flex justify-content-between">
              <div class="col"><h4>Type de Repas</h4></div>
              <div class="col text-right">
                  <b-form-checkbox :disabled="true" v-model="ODS_info.is_extra" name="check-button" switch>
                    Supplémentaire
                  </b-form-checkbox>
              </div>
            </div>
            
            <div class="row mt-2" v-for="(rep,i) in RepasService" :key="i">
              <div class="col" >
                <repas :key="rep" :is_extra="ODS_info.is_extra" :ODS="ODS_info.items" :pos="rep" :service="filtredRepas" :send="send" @getvalues="Repas[`${rep}`] = $event" />
              </div>
            </div>
          </div>
          
          
          <div class="row">
            <div class="col text-right mr-2 mb-3" v-if="ODS_info.id">
              <b-button :disabled="ODS_info.verified == 1 " @click="getTotalAmountODS()" variant="info" class="btn-label mr-2">
                  <i class="bx bx-check label-icon"></i> Contrôler
              </b-button>
              <b-button :disabled="ODS_info.verified == 1 || blocked" @click="onComplete" variant="primary" class="btn-label mr-2">
                  <i class="bx bx-check-double label-icon"></i> Enregitrer & Valider
              </b-button>
              <!-- <b-button :disabled="!ODS_info.verified" @click="onFenced" variant="success" class="btn-label">
                  <i class="bx bx-check-double label-icon"></i> Approuver & Clôturer
              </b-button> -->
              <!-- <button  class="btn btn-info mr-2"  :disabled="ODS_info.verified == 1" @click="onComplete"><i class="fas fa-check text-white"></i> Enregistrer & Confirmer</button> -->
              <!-- <button  class="btn btn-success" :disabled="!ODS_info.verified" @click="onFenced"><i class="fas fa-check-double text-white"></i> Approuver & Clôturer</button> -->
            </div>
          </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
